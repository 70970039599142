import './styles/App.scss';
import { Col, Container, Row } from 'react-bootstrap';
import { Alert } from 'react-bootstrap';


function App() {

  const skyStyle : React.CSSProperties = {
    backgroundImage: `url(${process.env.PUBLIC_URL}/sky.jpg)`,
    height: '500px'
  };

  return (
      <Container>
        <Row>
          <Col>
          <img src={`${process.env.PUBLIC_URL}/logo.jpeg`} style={{height: 100, padding: '10px'}} />
          </Col>
        </Row>
        <Row style={skyStyle}>
          <Col>
            <div className='main'>

              <p>
                Down to earth, pragmatic technology including:
              </p>

              <ul>
                <li>Leadership / Fractional CTO</li>
                <li>Technology infrastructure management</li>
                <li>Cloud project design and implementation</li>
                <li>Software design and implementation</li>
              </ul>

            </div>
            </Col>
        </Row>
        <Row>
          <Col className="footer">
          ittium ltd. Registered No. 5007211. Email us at <a href="mailto:hello@ittium.com">hello@ittium.com</a>.
          </Col>
        </Row>
      </Container>
      
    
  );
}

export default App;
